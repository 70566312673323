import React from 'react';
import {Tab, TabProps, Tabs} from 'react-bootstrap';

export interface TabOption extends TabProps {
  renderComponent: JSX.Element;
}

interface TabNavigatorProps {
  options: TabOption[];
  startIndex: number;
}

const TabNavigator: React.FC<TabNavigatorProps> = ({options, startIndex}) => {
  return (
    <Tabs
      defaultActiveKey={options[startIndex].eventKey}
      id="custom-tab-navigator"
      className="mb-3"
    >
      {options.map(item => (
        <Tab eventKey={item.eventKey} key={item.eventKey} title={item.title}>
          {item.renderComponent}
        </Tab>
      ))}
    </Tabs>
  );
};

export default TabNavigator;
